<template>
  <div class="right-menu">

    <subHeader pageName="DASHBOARD"/>

    <tutorialsAction />

    <div class="db_top">
      <div class="db-container">

        <moduleStats/>

        <!-- dashboard main stats block starts here -->
        <div class="redCtmTable mt-4" id="main-stats">
          <div class="tableNav">
            <ul>
              <li :class="duration == 'today' ? 'active' : ''" @click.prevent="changeStatsDuration('today')">
                <a href="#.">Today</a>
              </li>
              <li :class="duration == 'yesterday' ? 'active' : ''" @click.prevent="changeStatsDuration('yesterday')">
                <a href="#.">Yesterday</a>
              </li>
              <li :class="duration == 'week' ? 'active' : ''" @click.prevent="changeStatsDuration('week')">
                <a href="#.">Week</a>
              </li>
              <li :class="duration == '15Days' ? 'active' : ''" @click.prevent="changeStatsDuration('15Days')">
                <a href="#.">15 Days</a>
              </li>
              <li :class="duration == 'month' ? 'active' : ''" @click.prevent="changeStatsDuration('month')">
                <a href="#.">1 Month</a>
              </li>
              <li :class="duration == 'range' ? 'active' : ''">
                <a href="#." @click.prevent="">
                  Custom Date
                  <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    range-separator=" To "
                    :disabled="statsLoader || tourIsActive"
                    @change="changeStatsDuration('range')"
                    start-placeholder="Start date"
                    end-placeholder="End date"
                    ref="dashboardStats_DatePicker"
                  >
                  </el-date-picker>
                </a>
              </li>
            </ul>
          </div>
          <div class="db_info" v-if="statsLoader">
            <ul>
              <li>
                <p>ORDERS</p>
                <div class="amountList">
                  <vue-skeleton-loader
                    type="rect"
                    :width="60"
                    :height="40"
                    animation="fade"
                  />
                </div>
              </li>
              <li>
                <p>REVENUE</p>
                <div class="amountList">
                  <vue-skeleton-loader
                    type="rect"
                    :width="60"
                    :height="40"
                    animation="fade"
                  />
                </div>
              </li>
              <li>
                <p>NEW CUSTOMERS</p>
                <div class="amountList">
                  <vue-skeleton-loader
                    type="rect"
                    :width="60"
                    :height="40"
                    animation="fade"
                  />
                </div>
              </li>
              <li>
                <p>NEW VISITS</p>
                <div class="amountList">
                  <vue-skeleton-loader
                    type="rect"
                    :width="60"
                    :height="40"
                    animation="fade"
                  />
                </div>
              </li>
              <li>
                <p>NEW INQUIRIES</p>
                <div class="amountList">
                  <vue-skeleton-loader
                    type="rect"
                    :width="60"
                    :height="40"
                    animation="fade"
                  />
                </div>
              </li>
            </ul>
          </div>
          <div class="db_info" v-else>
            <ul>
              <li>
                <p>ORDERS</p>
                <div class="amountList">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21.005" height="23.996" viewBox="0 0 21.005 23.996" v-if="dashboardStats.orders.turnout == 'incr'">
                    <path id="arrow-downward-outline" d="M28.155,15.956a1.5,1.5,0,0,1-2.115.195L19.5,10.706V28.5a1.5,1.5,0,1,1-3,0V10.706L9.96,16.151a1.5,1.5,0,0,1-1.92-2.31l9-7.5.225-.135.195-.1a1.5,1.5,0,0,1,1.08,0l.195.1.225.135,9,7.5a1.5,1.5,0,0,1,.195,2.115Z" transform="translate(-7.498 -6)" fill="#1ec2c2"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21.005" height="23.996" viewBox="0 0 21.005 23.996" v-else-if="dashboardStats.orders.turnout == 'decr'">
                    <path id="arrow-downward-outline" d="M28.155,20.04a1.5,1.5,0,0,0-2.115-.195L19.5,25.29V7.5a1.5,1.5,0,1,0-3,0V25.29L9.96,19.845a1.5,1.5,0,0,0-1.92,2.31l9,7.5.225.135.195.1a1.5,1.5,0,0,0,1.08,0l.195-.1.225-.135,9-7.5a1.5,1.5,0,0,0,.195-2.115Z" transform="translate(-7.498 -6)" fill="#fd4d5d"/>
                  </svg>
                  <el-tooltip class="box-item" effect="dark" :content="dashboardStats.orders.count ? `${dashboardStats.orders.count}` : '0'" placement="bottom">
                    <h2>
                      {{ dashboardStats.orders.count ? Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(dashboardStats.orders.count) : 0 }}
                    </h2>
                  </el-tooltip>
                </div>
              </li>
              <li>
                <p>REVENUE</p>
                <div class="amountList">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21.005" height="23.996" viewBox="0 0 21.005 23.996" v-if="dashboardStats.revenue.turnout == 'incr'">
                    <path id="arrow-downward-outline" d="M28.155,15.956a1.5,1.5,0,0,1-2.115.195L19.5,10.706V28.5a1.5,1.5,0,1,1-3,0V10.706L9.96,16.151a1.5,1.5,0,0,1-1.92-2.31l9-7.5.225-.135.195-.1a1.5,1.5,0,0,1,1.08,0l.195.1.225.135,9,7.5a1.5,1.5,0,0,1,.195,2.115Z" transform="translate(-7.498 -6)" fill="#1ec2c2"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21.005" height="23.996" viewBox="0 0 21.005 23.996" v-else-if="dashboardStats.revenue.turnout == 'decr'">
                    <path id="arrow-downward-outline" d="M28.155,20.04a1.5,1.5,0,0,0-2.115-.195L19.5,25.29V7.5a1.5,1.5,0,1,0-3,0V25.29L9.96,19.845a1.5,1.5,0,0,0-1.92,2.31l9,7.5.225.135.195.1a1.5,1.5,0,0,0,1.08,0l.195-.1.225-.135,9-7.5a1.5,1.5,0,0,0,.195-2.115Z" transform="translate(-7.498 -6)" fill="#fd4d5d"/>
                  </svg>
                  <el-tooltip class="box-item" effect="dark" :content="stats && stats.revenue.total ? `${stats.revenue.total}` : '0'" placement="bottom">
                    <h2>
                      {{ stats && stats.revenue.total ? Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(stats.revenue.total) : 0}}
                    </h2>
                  </el-tooltip>
                </div>
              </li>
              <li>
                <p>NEW CUSTOMERS</p>
                <div class="amountList">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21.005" height="23.996" viewBox="0 0 21.005 23.996" v-if="dashboardStats.customers.turnout == 'incr'">
                    <path id="arrow-downward-outline" d="M28.155,15.956a1.5,1.5,0,0,1-2.115.195L19.5,10.706V28.5a1.5,1.5,0,1,1-3,0V10.706L9.96,16.151a1.5,1.5,0,0,1-1.92-2.31l9-7.5.225-.135.195-.1a1.5,1.5,0,0,1,1.08,0l.195.1.225.135,9,7.5a1.5,1.5,0,0,1,.195,2.115Z" transform="translate(-7.498 -6)" fill="#1ec2c2"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21.005" height="23.996" viewBox="0 0 21.005 23.996" v-else-if="dashboardStats.customers.turnout == 'decr'">
                    <path id="arrow-downward-outline" d="M28.155,20.04a1.5,1.5,0,0,0-2.115-.195L19.5,25.29V7.5a1.5,1.5,0,1,0-3,0V25.29L9.96,19.845a1.5,1.5,0,0,0-1.92,2.31l9,7.5.225.135.195.1a1.5,1.5,0,0,0,1.08,0l.195-.1.225-.135,9-7.5a1.5,1.5,0,0,0,.195-2.115Z" transform="translate(-7.498 -6)" fill="#fd4d5d"/>
                  </svg>
                  <el-tooltip class="box-item" effect="dark" :content="dashboardStats.customers.count ? `${dashboardStats.customers.count}` : '0'" placement="bottom">
                    <h2>
                      {{ dashboardStats.customers.count }}
                    </h2>
                  </el-tooltip>
                </div>
              </li>
              <li>
                <p>NEW VISITS</p>
                <div class="amountList">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21.005" height="23.996" viewBox="0 0 21.005 23.996" v-if="dashboardStats.visits.turnout == 'incr'">
                    <path id="arrow-downward-outline" d="M28.155,15.956a1.5,1.5,0,0,1-2.115.195L19.5,10.706V28.5a1.5,1.5,0,1,1-3,0V10.706L9.96,16.151a1.5,1.5,0,0,1-1.92-2.31l9-7.5.225-.135.195-.1a1.5,1.5,0,0,1,1.08,0l.195.1.225.135,9,7.5a1.5,1.5,0,0,1,.195,2.115Z" transform="translate(-7.498 -6)" fill="#1ec2c2"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21.005" height="23.996" viewBox="0 0 21.005 23.996" v-else-if="dashboardStats.visits.turnout == 'decr'">
                    <path id="arrow-downward-outline" d="M28.155,20.04a1.5,1.5,0,0,0-2.115-.195L19.5,25.29V7.5a1.5,1.5,0,1,0-3,0V25.29L9.96,19.845a1.5,1.5,0,0,0-1.92,2.31l9,7.5.225.135.195.1a1.5,1.5,0,0,0,1.08,0l.195-.1.225-.135,9-7.5a1.5,1.5,0,0,0,.195-2.115Z" transform="translate(-7.498 -6)" fill="#fd4d5d"/>
                  </svg>
                  <el-tooltip class="box-item" effect="dark" :content="stats && stats.visits.count ? `${stats.visits.count}` : '0'" placement="bottom">
                    <h2>
                      {{ stats && stats.visits.count ? Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(stats.visits.count) : 0}}
                    </h2>
                  </el-tooltip>
                </div>
              </li>
              <li>
                <p>NEW INQUIRIES</p>
                <div class="amountList">
                  <svg xmlns="http://www.w3.org/2000/svg" width="21.005" height="23.996" viewBox="0 0 21.005 23.996" v-if="dashboardStats.inquiries.turnout == 'incr'">
                    <path id="arrow-downward-outline" d="M28.155,15.956a1.5,1.5,0,0,1-2.115.195L19.5,10.706V28.5a1.5,1.5,0,1,1-3,0V10.706L9.96,16.151a1.5,1.5,0,0,1-1.92-2.31l9-7.5.225-.135.195-.1a1.5,1.5,0,0,1,1.08,0l.195.1.225.135,9,7.5a1.5,1.5,0,0,1,.195,2.115Z" transform="translate(-7.498 -6)" fill="#1ec2c2"/>
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" width="21.005" height="23.996" viewBox="0 0 21.005 23.996" v-else-if="dashboardStats.inquiries.turnout == 'decr'">
                    <path id="arrow-downward-outline" d="M28.155,20.04a1.5,1.5,0,0,0-2.115-.195L19.5,25.29V7.5a1.5,1.5,0,1,0-3,0V25.29L9.96,19.845a1.5,1.5,0,0,0-1.92,2.31l9,7.5.225.135.195.1a1.5,1.5,0,0,0,1.08,0l.195-.1.225-.135,9-7.5a1.5,1.5,0,0,0,.195-2.115Z" transform="translate(-7.498 -6)" fill="#fd4d5d"/>
                  </svg>
                  <el-tooltip class="box-item" effect="dark" :content="dashboardStats.inquiries.count ? `${dashboardStats.inquiries.count}` : '0'" placement="bottom">
                    <h2>
                      {{ dashboardStats.inquiries.count }}
                    </h2>
                  </el-tooltip>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <!-- dashboard main stats block ends here -->

        <!-- upgrade package block starts here -->

        <!-- upgrade package loader block starts here -->
        <div class="row" v-if="settingsIsPending && usagePercentage == 0">
          <div class="col-md-6">
            <div class="upgradeAccount">
              <div class="iconBlock">
                <vue-skeleton-loader
                  type="rect"
                  :width="62"
                  :height="55"
                  animation="fade"
                />
              </div>
              <div class="upgradeAccountTxt">
                <vue-skeleton-loader
                  type="rect"
                  :width="506"
                  :height="129"
                  animation="fade"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="upgradeAccount storageAccount">
              <div class="iconBlock">
                <vue-skeleton-loader
                  type="rect"
                  :width="62"
                  :height="55"
                  animation="fade"
                />
              </div>
              <div class="upgradeAccountTxt">
                <vue-skeleton-loader
                  type="rect"
                  :width="506"
                  :height="117"
                  animation="fade"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- upgrade package loader block ends here -->

        <div class="row" v-else-if="!settingsIsPending && usagePercentage <= 20">
          <div class="col-md-6">
            <div class="upgradeAccount">
              <div class="iconBlock">
                <img src="../../assets/images/alert.svg" alt="" />
              </div>
              <div class="upgradeAccountTxt">
                <h4>Upgrade to a paid account</h4>
                <p>Upgrading will unlock the ability to buy more features</p>
                <button @click.prevent="$router.push({ path: '/subscription' })">
                  Upgrade Account
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="upgradeAccount storageAccount">
              <div class="iconBlock">
                <img src="../../assets/images/gamification.svg" alt="" />
              </div>
              <div class="upgradeAccountTxt">
                <h4>Usage Storage</h4>
                <p>You have used 80% of your storage space, upgrade now</p>
                <div class="progressBarRow">
                  <div class="progressBarTrack">
                    <div class="progressBar" :style="{ width: 100 - usagePercentage + '%' }"></div>
                  </div>
                  <div class="progressBarCtmRow">
                    <div class="progressBarCtmColumn">
                      <p>{{ remainingSpace }}GB (Remaining)</p>
                    </div>
                    <div class="progressBarCtmColumn">
                      <p>{{ totalSpace }}GB</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <!-- upgrade package block ends here -->

        <!-- order table starts here -->
        <div v-if="roleCheck('manage orders')" class="orderTableComponent" id="orders-table">

          <OrderTable ref="order_table"/>

        </div>
        <!-- order table ends here -->


        <!-- charts row starts here -->
        <div class="row mt-4 newChartBlockBox ">
          <div class="statChartBlock col-md-7" id="customer-vs-visitors-chart">
            <div style="height:100%">
              <h2 class="chartHeading">Customers VS Visitors</h2>
              <div class="mainGrayBox">
                <div class="tableNav chartTabs" style="margin-bottom: 30px">
                  <ul class="darkDatePicker">
                    <li :class="lineGraphFilter == 'week' ? 'active' : ''" @click.prevent="changeLineGraphDuration('week')">
                      <a href="#.">LAST 7 DAYS</a>
                    </li>
                    <li :class="lineGraphFilter == '30Days' ? 'active' : ''" @click.prevent="changeLineGraphDuration('30Days')">
                      <a href="#.">LAST 30 DAYS</a>
                    </li>
                    <li :class="lineGraphFilter == '3months' ? 'active' : ''" @click.prevent="changeLineGraphDuration('3months')">
                      <a href="#.">LAST 3 MONTH</a>
                    </li>              
                  </ul>
                  <div :class="lineGraphFilter == 'range' ? 'active' : ''">
                    <a href="#.">
                      <!-- DATE RANGE -->
                      <el-date-picker
                        v-model="lineChartDateRange"
                        type="daterange"
                        range-separator=" To "
                        :disabled="lineGraphLoader || tourIsActive"
                        @change="changeLineGraphDuration('range')"
                        start-placeholder="Start date"
                        end-placeholder="End date"
                      >
                      </el-date-picker>
                    </a>
                  </div>
                </div>
                <div class="graph-poligon">
                  <LineChart :height="180" />
                </div>
              </div>
            </div>
          </div>
          <div class="statChartBlock col-md-5" id="sales-vs-expense-chart">
            <div style="height:100%">
              <h2 class="chartHeading">Sales VS Expenses</h2>
              <div class="mainGrayBox">
                <div class="chartMainHeading">
                  <!-- <h2>SALES VS EXPENSES</h2> -->
                  <form action="">
                    <select class="form-control" aria-label="Default select example" v-model="selectedYear" :disabled="tourIsActive">
                      <option v-for="(year, index) in yearsList" :key="index" :value="year" :selected="index == 1">{{ year }}</option>
                    </select>
                  </form>
                </div>
                <div class="chartBox">
                  <BarChart :year="selectedYear" :height="285" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- charts row starts here -->

        <!-- top selling products, recent sold products, freedom from payment and orders status row starts here -->
        <div class="row mt-4 flexSection">
          <div class="newSectionsBox" id="productStatsBox" style="display: block;">
            <div class="topProductsWhiteBox">
              <div class="row align-items-center titleArea">
                <div class="col-md-8">
                  <div class="topProductsTabBtns">
                    <button :class="productStatsFilter == 'topSelling' ?  'activeBtn' :''" @click.prevent="dashboardProductStatsFilterHandle('topSelling')">Top Selling Products</button>
                    <button :class="productStatsFilter == 'recentSold' ? 'activeBtn' : ''" @click.prevent="dashboardProductStatsFilterHandle('recentSold')">Recent Sold Products</button>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="ctmSlideArrow">
                    <button @click.prevent="loadPreviousDashboardProductStatsHandle" :disabled="dashboardProductStatsCurrentPage == 1" :class="dashboardProductStatsCurrentPage == 1 ? 'disabledBtn' : 'activeBtn'">
                      <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <title/>
                        <g data-name="Layer 2" id="Layer_2">
                          <path d="M10.1,23a1,1,0,0,0,0-1.41L5.5,17H29.05a1,1,0,0,0,0-2H5.53l4.57-4.57A1,1,0,0,0,8.68,9L2.32,15.37a.9.9,0,0,0,0,1.27L8.68,23A1,1,0,0,0,10.1,23Z"/>
                        </g>
                      </svg>
                    </button>
                    <button @click.prevent="loadNextDashboardProductStatsHandle" :disabled="dashboardProductStatsCurrentPage == dashboardProductStatsLastPage" :class="dashboardProductStatsCurrentPage == dashboardProductStatsLastPage ? 'disabledBtn' : 'activeBtn'">
                      <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
                        <title/>
                        <g data-name="Layer 2" id="Layer_2">
                          <path d="M22,9a1,1,0,0,0,0,1.42l4.6,4.6H3.06a1,1,0,1,0,0,2H26.58L22,21.59A1,1,0,0,0,22,23a1,1,0,0,0,1.41,0l6.36-6.36a.88.88,0,0,0,0-1.27L23.42,9A1,1,0,0,0,22,9Z"/>
                        </g>
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="topProductsList" v-if="dashboardProductStatsLoader">
                <ul>
                  <li v-for="i in 10" :key="i">
                    <div class="row">
                      <div class="col-10">
                        <div class="productItemNameTxt">
                          <vue-skeleton-loader
                            type="rect"
                            :width="262"
                            :height="20"
                            animation="fade"
                          />
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="productItemActionBtn">
                          <vue-skeleton-loader
                            type="rect"
                            :width="20"
                            :height="20"
                            animation="fade"
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="topProductsList" v-else-if="!dashboardProductStatsLoader && productStats.length > 0">
                <ul v-if="productStats.length > 0">
                  <li v-for="(productStat,index) in productStats" :key="index">
                    <div class="row">
                      <div class="col-10">
                        <div class="productItemNameTxt">
                          <h4>{{ index + 1 }}.</h4>
                          <p>{{ productStat.product_name }}</p>
                        </div>
                      </div>
                      <div class="col-2">
                        <div class="productItemActionBtn">
                          <button @click.prevent="$router.push({path: '/product' , query:{search: productStat.product_name}});" :disabled="tourIsActive">
                            <svg class="feather feather-arrow-up-right" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                              <line x1="7" x2="17" y1="17" y2="7"/>
                              <polyline points="7 7 17 7 17 17"/>
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="emptyProductList" v-else>
                  <p> No Record Available </p>
                </div>
            </div>

            <!-- <ins 
              class="adsbygoogle"
              style="display:block"
              data-ad-client="ca-pub-6901120971014785"
              data-ad-slot="9122535992"
              data-ad-format="auto"
              data-full-width-responsive="true"
            >
            </ins>
            <div class="recommendationsBox">
              <div class="recommendationContentBox">
                <h2>Recommendations</h2>
                <p>Improve health of your product catalogue we recommended :</p>
                <ul>
                  <li>Upload high quality images</li>
                  <li>Adding product vidoes</li>
                  <li>Detailed specifications</li>
                  <li>3D images</li>
                  <li>Downloadable product brochures</li>
                </ul>
              </div>
              <div class="recommendationImgBox">
                <img src="../../assets/images/recommendationsImg.png" alt="" />
              </div>
            </div> -->

          </div>
          <div class="newSectionsBox">
            <div class="newBannerBox newBannerBox1" id="payment-section">
              <h3>Freedom <span>from <br/> payment</span></h3>
              <p>No upfront payment is required for regular customers to avail our smart and flexible payment solutions.</p>
              <button @click.prevent=" $router.push({ path: '/subscription' })" :disabled="tourIsActive">Apply Now</button>
            </div>
            <div class="newBannerBox newBannerBox2" id="order-stats" v-if="this.storeDashboardOrderStatsLoader">
              <vue-skeleton-loader
                type="rect"
                :width="330"
                :height="288"
                animation="fade"
              />
            </div>
            <div class="newBannerBox newBannerBox2" id="order-stats" v-else>
              <h2>Orders Status</h2>
              <div class="chart-skills"></div>
              <div class="chartInfo">
                <h2>
                  {{ dashboardOrderStats.total_orders ? Intl.NumberFormat('en-US', {notation: "compact",maximumFractionDigits: 1}).format(dashboardOrderStats.total_orders) : 0 }}
                </h2>
                <p>Orders</p>
              </div>
              <div class="progressBarsMainBox">
                <div>
                  <h3>{{ dashboardOrderStats.delivered_orders_percentage }}%</h3>
                  <span>Delivered</span>
                </div>
                <div>
                  <h3>{{ dashboardOrderStats.pending_orders_percentage }}%</h3>
                  <span>Pending</span>
                </div>
                <div>
                  <h3>{{ dashboardOrderStats.cancelled_orders_percentage }}%</h3>
                  <span>Cancelled</span>
                </div>
              </div>
              <div class="moreProductsLink">
                <a href='' class="moreLink" @click.prevent="ordersModuleRedirect()">+ Add More Orders and Sell More</a>
              </div>
            </div>
          </div>
        </div> 
        <!-- top selling products, recent sold products, freedom from payment and orders status row ends here -->


        <!-- sales and forms summary row starts here -->
        <div class="row mt-4 newChart2Blocks" >
          <div class="newChart2BlockInnerBox newChart2BlockInnerBox1 col-md-5" >
            <div class="chartHandleBox" id="sales-distribution-chart">
              <h2 class="chartHeading">Sales Distribution</h2>
              <div>
                <div class="mainGrayBox" >
                  <div class="tableNav chartTabs">
                    <ul>
                      <li :class="pieChartFilter == 'topCities' ? 'active' : ''" @click.prevent="pieChartFilterChange('topCities')">
                        <a href="#.">BY TOP CITY</a>
                      </li>
                      <li :class="pieChartFilter == 'topCategories' ? 'active' : ''" @click.prevent="pieChartFilterChange('topCategories')">
                        <a href="#.">BY TOP CATEGORIES</a>
                      </li>
                      <li :class="pieChartFilter == 'topProducts' ? 'active' : ''" @click.prevent="pieChartFilterChange('topProducts')">
                        <a href="#.">BY TOP PRODUCTS</a>
                      </li>
                    </ul>
                  </div>
                  <div class="chartBox">
                    <DoughnutChart :width="10" :height="5"/>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="newChart2BlockInnerBox newChart2BlockInnerBox2 col-md-7" id="form-submissions-table">
            <div class="chartHandleBox"> 
              <h2 class="chartHeading">Forms Summary</h2>
              <div class="commantChatBox" ref="messageViewBox" :class="selectedConversation != null ? 'active' : ''" >
                <div class="commantChatBoxInnerBox">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>FORM TYPE</th>
                        <th>FROM</th>
                        <th>MESSAGE</th>
                        <th>DATE</th>
                      </tr>
                    </thead>
                    <tbody v-if="formSummariesLoader">
                      <tr v-for="i in 5" :key="i">
                        <td>
                          <vue-skeleton-loader
                            type="rect"
                            :width="87"
                            :height="25"
                            animation="fade"
                          />
                        </td>
                        <td>
                          <vue-skeleton-loader
                            type="rect"
                            :width="150"
                            :height="25"
                            animation="fade"
                          />
                        </td>
                        <td>
                          <vue-skeleton-loader
                            type="rect"
                            :width="300"
                            :height="25"
                            animation="fade"
                          />
                        </td>
                        <td>
                          <vue-skeleton-loader
                            type="rect"
                            :width="125"
                            :height="25"
                            animation="fade"
                          />
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr v-for="(form, index) in forms" :key="index">
                        <td><b>{{ form.form_type }}</b></td>
                        <td>{{ form.from }}</td>
                        <td>{{ form.message }}</td>
                        <td>{{ form.created_at ? new Date(form.created_at).toLocaleDateString("en-US") : 0 }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div> 
        <!-- sales and forms summary row starts here -->


        <!-- notifications section starts here -->
        <div class="categoryTableWrapBox row mt-4" id="notifications-table">
          <h2 class="chartHeading">Notifications</h2>
          <div class="categoryTableScrollBox" ref="categoriesTable">
            <table>
              <thead>
                <tr>
                  <th>
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Category Type">Sr.No</p>
                    </div>
                  </th>
                  <th>
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Parent Category">Subject</p>
                    </div>
                  </th>
                  <th>
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Active/Hide">Date</p>
                    </div>
                  </th>
                  <th>
                    <div class="ctmTableTitleWrap">
                      <p data-toggle="tooltip" data-placement="top" title="Action">Action</p>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody v-if="notificationsLoader">
                <tr v-for="i in 5" :key="i">
                  <td>
                    <div class="ctmCheckWrap">
                      <vue-skeleton-loader
                        type="rect"
                        :width="17"
                        :height="20"
                        animation="fade"
                      />
                      <div class="ctmDataTableImgBox">
                        <vue-skeleton-loader
                          type="circle"
                          :width="36"
                          :height="36"
                          animation="fade"
                        />
                      </div> 
                    </div>
                  </td>
                  <td class="mainTableTitle">
                    <div class="editFieldTitle">
                      <vue-skeleton-loader
                        type="rect"
                        :width="200"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td>
                    <div class="tableBodyCtmTxt">
                      <vue-skeleton-loader
                        type="rect"
                        :width="250"
                        :height="20"
                        animation="fade"
                      />
                    </div>
                  </td>
                  <td style="position: relative;">
                    <vue-skeleton-loader
                      type="rect"
                      :width="95"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td>
                    <vue-skeleton-loader
                      type="rect"
                      :width="175"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                  <td>
                    <vue-skeleton-loader
                      type="rect"
                      :width="102"
                      :height="20"
                      animation="fade"
                    />
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-if="notificationsList && notificationsList.length == 0" id="new-preview-record">
                  <td colspan="6" style="text-align:center"><p class="mb-0">No Notifications Available</p></td>
                </tr>
                <tr v-for="(notification, index) in notificationsList" :key="index" id="new-preview-record" @click.prevent="notificationDetailModal(notification)">

                  <td>{{index+1}}</td>

                  <td>{{notification.subject}}</td>

                  <td>On {{notification.created_at ? new Date(notification.created_at).toLocaleString("en-US",{year: 'numeric',month: 'numeric',day: 'numeric'}) : 'Not Available'}}
                  At {{notification.created_at ? new Date(notification.created_at).toLocaleTimeString('en-US') : "Not Available"}}</td>

                  <!-- action block starts here -->
                  <td>
                    <div class="ctmDataTableActionBtn" data-open="true">
                      <button class="delFunction" data-open="false">
                        <i aria-hidden="true" class="fa fa-envelope-open-o" data-open="false"></i>
                      </button>
                    </div>
                  </td>
                  <!-- action block ends here -->

                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- notifications section ends here -->

        <!-- notification details popup starts here -->
        <div class="modalBg" :class="viewNotification ? 'modal-active': ''">
          <div class="modalLayers" @click.prevent="closeModal"></div>
          <div class="modalBody">
            <div class="vueSelectBlock mb-4">
              <Multiselect
                v-model="recipientsList"
                :options="options"
                :multiple="true"
                group-values="emails"
                group-label="selectAll"
                :group-select="true"
                placeholder="Recipients"
                track-by="email"
                label="email"
                disabled
              />
            </div>
            <div class="tableFromFieldItem mb-4">
              <input type="text" name="subject" id="subject" placeholder="Subject" v-model="detailedNotification.subject" disabled>
            </div>
            <div class="allCustomersField notificationsBox mb-0">
              <quill-editor  
                name="comment"
                form="usrform"
                :options="editorOptions"
                v-model="detailedNotification.body"
                disabled
              >
              </quill-editor>
            </div>
            <div class="modalCloseIcon">
              <button @click.prevent="closeModal">
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
                  <path id="close" d="M31,10.925,29.075,9,20,18.075,10.925,9,9,10.925,18.075,20,9,29.075,10.925,31,20,21.925,29.075,31,31,29.075,21.925,20Z" transform="translate(-9 -9)" fill="#22678d">
                  </path>
                </svg>
              </button>
            </div>
          </div>
        </div>
        <!-- notification details popup ends here -->

      </div>

      <Footer />

    </div>

    <!-- dashboard tour-->
    <v-tour name="dashboardTour" :steps="tourSteps" :options="tourOptions" :callbacks="tourCallbacks"></v-tour>

  </div>
</template>
<script>
import OrderTable from "../../components/OrderTable.vue";
import DoughnutChart from "../../components/charts/DoughnutChart.vue";
import BarChart from "../../components/charts/BarChart.vue";
import LineChart from "../../components/charts/LineChart.vue";
import { mapGetters } from "vuex";
import mainHeader from "../../components/mainHeader.vue";
import subHeader from "../../components/subHeader.vue";
import Footer from "../../components/footer.vue";
import Multiselect from 'vue-multiselect';
import moduleStats from "../../components/moduleStats.vue";
import tutorialsAction from "../../components/tutorialsAction.vue";
import VueQr from 'vue-qr';
import OtpInput from "@bachdgvn/vue-otp-input";

export default {
  name:'dashboard',
  metaInfo() {
    return {
      title: 'Dashboard | YeetCommerce', 
    };
  },
  data() {
    return {
      selectedOrder: null,
      selectedConversation: null,
      forms: [],
      store_url: process.env.VUE_APP_STORE_IMAGE,
      dashboardStats: {
        orders: {
          count: 0,
          turnout: "incr",
        },
        revenue: {
          total: 0,
          turnout: "incr",
        },
        customers: {
          count: 0,
          turnout: "incr",
        },
        visits: {
          count: 0,
          turnout: "incr",
        },
        inquiries: {
          count: 0,
          turnout: "incr",
        },
      },
      usagePercentage: 0,
      totalSpace: 0,
      remainingSpace: 0,
      dateRange: [],
      selectedYear: "",
      yearsList: [],
      barChartLoader: false,
      lineChartDateRange: [],
      selectedChartFilter: "",
      searchedKeyword:'',
      //Notifications
      notificationsList: [],
      notificationsLoader:false,
      detailedNotification:[],
      viewNotification:false,
      recipientsList:[],
      options: [
        {
          selectAll: 'Select All',
          emails: []
        },
      ],
      //dashboard Order stats
      dashboardOrderStats:[],
      //dashboard product stats
      productStats:[],
      productStatsFilter:'',
      editorOptions:{
        Placeholder: 'please enter',
        theme: 'snow',
        modules:{
          toolbar:{
              container:[ [{ "font": [] },],
              [{'header': [1, 2, 3, 4, 5, 6, false]}],
              ["blockquote", "code-block"],
              ['bold', 'italic', 'underline', 'strike'],
              ["clean"],
              [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
              [{ 'script': 'sub'}, { 'script': 'super' }],
              [{'color': []}, {'background': []}],
              [{ 'direction': 'rtl' }, { 'align': [] }],
              ],
            }
        }
      },
      //vue-tour
      tourOptions: {
        useKeyboardNavigation: false,
        labels: {
          buttonSkip: 'Skip tour',
          buttonPrevious: 'Previous',
          buttonNext: 'Next',
          buttonStop: 'Finish Tour'
        }
      },
      tourCallbacks: {
        onPreviousStep: this.handlePreviousStep,
        onNextStep: this.handleNextStep,
        // onStart: this.handleTourStart,
        onStop: this.handleTourStop,
        onFinish: this.handleTourFinish,
        onSkip: this.handleTourSkip
      },
      tourSteps:[
        //header steps
        {
          target: '#tour-start-btn',
          content: `<strong>Start Tour</strong><br>Provides insights into the available functionalities within a specific menu, helping you understand what actions you can perform.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement: 'bottom'
          }
        },
        {
          target: '#master-search',
          content: `<strong>Master Search</strong><br>Enables you to perform searches from all over the dashboard.`,
          params: {
            highlight: true,
            enableScrolling: false
          },
        },
        {
          target: '#add-new-dropDown',
          content: `<strong>Add New Record</strong><br>Enables you to add records.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#help-link',
          content: `<strong>Help</strong><br>If you require any assistance, feel free to reach out to us.`,
          params: {
            highlight: true,
            enableScrolling: false
          }
        },
        {
          target: '#user-options-dropdown',
          content: `<strong>User Information</strong><br>Presents essential details about you along with a selection of available options.`,
          params: {
            highlight: true,
            enableScrolling: true
          }
        },
        //dashboard module steps
        {
          target: '#module-stats',
          content: `<strong>Dashboard Statistics</strong><br>Provides information about your orders, delayed orders, low stock products,
          sales to visitors conversion , sales, expenses vs sales.`,
          params: {
            highlight: true,
            enableScrolling: true
          }
        },
        {
          target: '#main-stats',
          content: `<strong>Dashboard Statistics</strong><br>Provides information about your orders, revenue, new customers, visits, and inquiries.`,
          params: {
            highlight: true,
            enableScrolling: true
          }
        },
        {
          target: '#orders-table',
          content: `<strong>Orders</strong><br>Displays your orders and allows you to make edits conveniently.`,
          params: {
            highlight: true,
            enableScrolling: true
          }
        },
        {
          target: '#customer-vs-visitors-chart',
          content: `<strong>Customers Vs Visitors</strong><br>Provides a comparison between customer visits to your site and customer registrations.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'bottom'
          }
        },
        {
          target: '#sales-vs-expense-chart',
          content: `<strong>Sales Vs Expenses</strong><br>Presents the monthly variance between your sales and expenses against a selected year.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'right'
          }
        },
        {
          target: '#productStatsBox',
          content: `<strong>Top Products</strong><br>This section displays a list of the most selling and recently sold products.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'top'
          }
        },
        {
          target: '#payment-section',
          content: `<strong>Payment Plans</strong><br>This section displays the recommendation about the payment plans.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'bottom'
          }
        },
        {
          target: '#order-stats',
          content: `<strong>Order Stats</strong><br>This section showcases statistics pertaining to orders.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'left'
          }
        },
        {
          target: '#sales-distribution-chart',
          content: `<strong>Sales Distribution</strong><br>Displays the sales distribution based on the top six cities, categories, and products.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'left'
          }
        },
        {
          target: '#form-submissions-table',
          content: `<strong>Inquiries & Newsletter Subscriptions</strong><br>Shows the user inquiries and newsletter subscriptions.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'bottom'
          }
        },
        {
          target: '#notifications-table',
          content: `<strong>Notifications</strong><br>This table presents the most recent email notifications.`,
          params: {
            highlight: true,
            enableScrolling: true,
            placement:'top'
          }
        },
      ],
      tourIsActive:false,
    }
  },
  components: {
    OrderTable,
    DoughnutChart,
    BarChart,
    LineChart,
    mainHeader,
    subHeader,
    Footer,
    Multiselect,
    moduleStats,
    VueQr,
    OtpInput,
    tutorialsAction
  },
  methods: {
    notificationDetailModal(notification){

      if(!this.tourIsActive){

        this.detailedNotification = _.cloneDeep(notification)

        let recipients = [];
        notification.recipients.forEach(recipient => {

          recipients.push(recipient.user);

        });

        this.options[0].emails = recipients;
        this.recipientsList = recipients;

        this.viewNotification = true;

      }

    },
    closeModal(){

      this.viewNotification = false;

      this.recipientsList = [];
      this.detailedNotification = [];

    },
    async changeStatsDuration(duration){

      if(!this.tourIsActive){

        if(!this.statsLoader){

          if(duration == "range"){

            if(this.dateRange){

              let from = new Date(this.dateRange[0]);
              let to = new Date(this.dateRange[1]);

              let fromDate = [from.getFullYear(),from.getMonth() + 1,from.getDate(),].join("-");

              let toDate = [to.getFullYear(), to.getMonth() + 1, to.getDate()].join("-");

              let payload = {
                filter: "range",
                from: fromDate,
                to: toDate,
              };

              this.$store.commit("dashboard_stats/rangeDuration", payload);
              this.$store.dispatch("dashboard_stats/fetchStatsRequest", payload);

            }else{

              let alternateDuration = "today";
              this.$store.dispatch("dashboard_stats/fetchStatsRequest",alternateDuration);

            }

          }else{

            this.dateRange = []

            this.$store.commit("dashboard_stats/durationChange", duration);
            this.$store.dispatch("dashboard_stats/fetchStatsRequest", duration);

          }

        }

      }

    },
    async changeLineGraphDuration(duration){

      if(!this.tourIsActive){

        if(!this.lineGraphLoader){

          let payload = {
            days: 0,
            filter: "",
          };

          if(duration == "week"){

            payload.days = 7;
            payload.filter = duration;

            this.lineChartDateRange = []

            this.$store.dispatch("line_graph_module/fetchLineGraphRequest",payload);

          }

          if(duration == "30Days"){

            payload.days = 30;
            payload.filter = duration;

            this.lineChartDateRange = []

            this.$store.dispatch("line_graph_module/fetchLineGraphRequest",payload);

          }

          if(duration == "3months"){

            payload.days = 0;
            payload.filter = duration;

            this.lineChartDateRange = []

            this.$store.dispatch("line_graph_module/fetchLineGraphRequest",payload);

          }

          if(duration == "range"){

            if(this.lineChartDateRange){

              let from = new Date(this.lineChartDateRange[0]);
              let to = new Date(this.lineChartDateRange[1]);

              let fromDate = [from.getFullYear(),from.getMonth() + 1,from.getDate()].join("-");

              let toDate = [to.getFullYear(), to.getMonth() + 1, to.getDate()].join("-");

              let payload = {
                filter: duration,
                from: fromDate,
                to: toDate,
              };

              this.$store.dispatch("line_graph_module/fetchLineGraphRequest",payload);

            }else{

              payload.days = 7;

              payload.filter = "week";

              this.$store.dispatch("line_graph_module/fetchLineGraphRequest",payload);

            }

          }

        }

      }

    },
    pieChartFilterChange(filter){

      if(!this.tourIsActive){

        if(!this.pieChartLoader){

          this.selectedChartFilter = filter;

        }

      }

    },
    roleCheck(role){

      let isFound = false;

      this.$store.state.roles.forEach((userRole) => {

        if(userRole.name == role){

          isFound = true;

        }

      });

      return isFound;

    },
    //dashboard product stats handle
    dashboardProductStatsFilterHandle(filter){

      this.productStatsFilter = filter;

      this.$store.dispatch('dashboard_product_stats/fetchStatsRequest',{filter:filter,page: 1});

    },
    loadNextDashboardProductStatsHandle(){

      if(this.dashboardProductStatsCurrentPage < this.dashboardProductStatsLastPage){
       
        this.$store.dispatch('dashboard_product_stats/fetchStatsRequest',{filter:this.productStatsFilter,page:this.dashboardProductStatsCurrentPage + 1})
      
      }

    },
    loadPreviousDashboardProductStatsHandle(){

      if(this.dashboardProductStatsCurrentPage > 1){

        this.$store.dispatch('dashboard_product_stats/fetchStatsRequest',{filter:this.productStatsFilter,page:this.dashboardProductStatsCurrentPage - 1})
      
      }

    },
    //tour methods
    startDashboardTour(){

      this.$tours['dashboardTour'].start();

      this.tourIsActive = true

      this.$refs.order_table.tourIsActive = true

    },
    handleNextStep(currentStep){

    },
    handlePreviousStep(currentStep){

    },
    handleTourSkip(){

      this.tourIsActive = false

      this.$refs.order_table.tourIsActive = false

    },
    handleTourStop(){

      this.tourIsActive = false

      this.$refs.order_table.tourIsActive = false

    },
    handleTourFinish(){

      this.tourIsActive = false

      this.$refs.order_table.tourIsActive = false

    },
    ordersModuleRedirect(){

      if(!this.tourIsActive){

        this.$router.push({ name: 'order' , params:{isCreateNew: true} })

      }

    },
    //Adsense
    // adsenseAddLoad(){
    //     let inlineScript   = document.createElement("script");
    //     inlineScript.type  = "text/javascript";
    //     inlineScript.text  = '(adsbygoogle = window.adsbygoogle || []).push({});'
    //     document.getElementsByTagName('body')[0].appendChild(inlineScript);
    // }
  },
  computed: {
    ...mapGetters({
      settings: "settings_module/settings",
      settingsIsPending: "settings_module/isPending",
      subscription: "subscription_module/subscription",
      stats: "dashboard_stats/stats",
      duration: "dashboard_stats/duration",
      statsFrom: "dashboard_stats/from",
      statsTo: "dashboard_stats/to",
      statsLoader: "dashboard_stats/isPending",
      yearRecord: "bar_graph_module/storeYear",
      lineGraph: "line_graph_module/lineGraphData",
      lineGraphFilter: "line_graph_module/selectedFilter",
      lineGraphLoader:'line_graph_module/isPending',
      rangeFrom: "line_graph_module/from",
      rangeTo: "line_graph_module/to",
      pieChartFilter: "pie_graph_module/selectedFilter",
      pieChartLoader:'pie_graph_module/isPending',
      logoutIsPending:'logoutIsPending',
      storeCountries:'countries_module/countries',
      storeAllCountries: 'countries_module/allCountries',
      storeFormSummaries: 'form_summary_module/formSummaries',
      formSummariesLoader: 'form_summary_module/isPending',
      //notifications
      storeNotifications:'notifications_module/notifications',
      storeNotificationsLoader:'notifications_module/isPending',
      //dashboard order stats
      storeDashboardOrderStats:'dashboard_order_stats/stats',
      storeDashboardOrderStatsLoader:'dashboard_order_stats/isPending',
      //dashboard product stats
      dashboardProductStats:'dashboard_product_stats/stats',
      dashboardProductStatsCurrentPage:'dashboard_product_stats/currentPage',
      dashboardProductStatsLastPage:'dashboard_product_stats/lastPage',
      dashboardProductStatsLoader:'dashboard_product_stats/isPending',
      dashboardProductStatsFilter:'dashboard_product_stats/filter'
    }),
  },
  watch: {
    stats: {
      handler: function (val){

        if(val){

          this.dashboardStats = val;

        }else{

          this.dashboardStats = {
            orders: {
              count: 0,
              turnout: "incr",
            },
            revenue: {
              total: 0,
              turnout: "incr",
            },
            customers: {
              count: 0,
              turnout: "incr",
            },
            visits: {
              count: 0,
              turnout: "incr",
            },
            inquiries: {
              count: 0,
              turnout: "incr",
            },
          }

        }

      },
      deep: true,
    },
    settings: {
      handler: function (val){

        if(val.subscription){

          let raw = (val.subscription.usage_space / val.subscription.package.total_space) * 100;

          this.usagePercentage = Math.round(raw);

          let totalSpace = (val.subscription.package.total_space / (1024 * 1024));
          let remainingSpace = (val.subscription.usage_space / (1024 * 1024));

          this.totalSpace = totalSpace.toFixed(2);
          this.remainingSpace = remainingSpace.toFixed(2);

          let storeYear = new Date(val.created_at).getFullYear();

          let thisYear = new Date().getFullYear();

          if(this.yearsList.length == 0){

            if(thisYear == storeYear){

              this.yearsList.push(thisYear);

            }else if(thisYear > storeYear){

              for(let i = thisYear; i >= storeYear; i--){

                this.yearsList.push(i);

              }

            }

            this.selectedYear = this.yearsList[0];

          }

        }

      },
      deep: true,
    },
    selectedYear: {
      handler: function(value){

        this.$store.commit("bar_graph_module/year_change", value);

      },
      deep: true,
    },
    selectedChartFilter: {
      handler: function(value){

        let payload = {
          filter: value,
        };

        this.$store.dispatch("pie_graph_module/fetchPieGraphRequest", payload);

      },
    },
    storeFormSummaries:{
      handler:function(value){

        this.forms = value;

      },deep:true
    },
    storeNotifications:{
      handler:function(value){

        this.notificationsList = value;
        this.notificationsLoader = false;

      },deep:true
    },
    storeDashboardOrderStats:{
      handler:function(value){

        this.dashboardOrderStats = value;

      },deep:true
    },
    dashboardProductStats:{
      handler:function(value){

        this.productStats = value;

      },deep:true
    },
    dashboardProductStatsFilter:{
      handler:function(value){

        if(value && value.length > 0){

          this.productStatsFilter = value;

        }
        
      },deep:true
    }
  },
  async beforeMount(){

    if(!this.settings || this.settings.length == 0 || this.settings.error){

      this.$store.commit("bar_graph_module/fetch_bar_graph_request");

    }else{

      let raw = (this.settings.subscription.usage_space /this.settings.subscription.package.total_space) * 100;

      this.usagePercentage = Math.round(raw);

      let totalSpace = (this.settings.subscription.package.total_space / (1024 * 1024));
      let remainingSpace = (this.settings.subscription.usage_space / (1024 * 1024));

      this.totalSpace = totalSpace.toFixed(2);
      this.remainingSpace = remainingSpace.toFixed(2);

      let storeYear = new Date(this.settings.created_at).getFullYear();

      let thisYear = new Date().getFullYear();

      if(thisYear == storeYear){

        this.yearsList.push(thisYear);

      }else if(thisYear > storeYear) {

        for(let i = thisYear; i >= storeYear; i--){

          this.yearsList.push(i);

        }

      }

      if(this.yearRecord){

        this.selectedYear = this.yearRecord;

      }else{

        this.selectedYear = this.yearsList[0];

      }

      this.barChartLoader = false;
    }

    if(!this.storeCountries || this.storeCountries.length == 0){

      this.$store.dispatch('countries_module/fetchStoreCountriesRequest');

    }

    if(!this.storeAllCountries || this.storeAllCountries.length == 0){

      this.$store.dispatch('countries_module/fetchAllCountries');

    }

    if(!this.dashboardProductStats || this.dashboardProductStats.length == 0){

      this.$store.dispatch('dashboard_product_stats/fetchStatsRequest',{filter: 'topSelling',page: 1});

    }else{

      this.productStats = this.dashboardProductStats;
      this.productStatsFilter = this.dashboardProductStatsFilter;

    }

    if(!this.stats || this.stats.length == 0 || this.stats.error){

      let duration = "today";
      this.$store.dispatch("dashboard_stats/fetchStatsRequest", duration);

    }else{

      if(this.duration == "range"){

        this.dateRange[0] = this.statsFrom;
        this.dateRange[1] = this.statsTo;
        this.dashboardStats = this.stats;

      }else{

        this.dashboardStats = this.stats;

      }

    }

    if(!this.lineGraph || this.lineGraph.length == 0 || this.lineGraph.error){

      let payload = {
        days: 7,
        filter: "week",
      };
      this.$store.dispatch("line_graph_module/fetchLineGraphRequest", payload);

    }else{

      if(this.lineGraphFilter == "range"){

        this.lineChartDateRange[0] = this.rangeFrom;
        this.lineChartDateRange[1] = this.rangeTo;

      }

    }

    if(!this.storeFormSummaries || this.storeFormSummaries.length == 0){

      this.$store.dispatch('form_summary_module/fetchFormSummaryRequest')

    }else{

      this.forms = this.storeFormSummaries;

    }

    if(!this.subscription || this.subscription.length == 0 || this.subscription.error){

      this.$store.dispatch("subscription_module/fetchSubscriptionRequest");

    }
  
    if(!this.storeNotifications || this.storeNotifications.length == 0){

      this.notificationsLoader = true;
      this.$store.dispatch('notifications_module/fetchStoreNotificationsRequest',{size:10, page: 1, loadData: 'new'});

    }else{

      this.notificationsLoader = false;
      this.notificationsList = this.storeNotifications;

    }

    if(!this.storeDashboardOrderStats || this.storeDashboardOrderStats.length == 0){

      this.$store.dispatch("dashboard_order_stats/fetchStatsRequest");

    }else{

      this.dashboardOrderStats = this.storeDashboardOrderStats;

    }

  },
  mounted(){
    // this.adsenseAddLoad();
  },
  beforeDestroy(){
  },
};
</script>

<style scoped>

  .chartHeading{
    font-size: 24px;
    color: #000000;
    font-family: 'Poppins', sans-serif;
    font-weight: 500;
    margin-bottom: 12px;
  }

  .v-tour__target--highlighted{
    z-index: 100 !important;
  }


  /* Nav Table CSS Start Here */
  .tableNav ul{
    display: flex;
    align-items: center;
  }
  .tableNav ul li a::after {
    content: "";
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0px;
    margin: 0 auto;
    width: 100%;
    height: 3px!important;
    background: #1EC2C2;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .tableNav ul li:last-child a::after {
    content: "";
    position: absolute;
    bottom: -12px;
    left: 0;
    right: 0px;
    margin: 0 auto;
    width: 100%;
    height: 3px!important;
    background: #1EC2C2;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .redCtmTable .tableNav ul li:nth-last-child(1){
    margin-right: 0;
    margin-left: auto;
  }
  /* Nav Table CSS End Here */

  /* StatChartBlock CSS Start Here */
  .newChartBlockBox{
    height: 100%;
  }
  .newChartBlockBox .statChartBlock{
    height: 100%;
  }
  .statChartBlock .chartTabs{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .statChartBlock .chartTabs .tableNav ul li{
    margin-right: 20px!important;
  }
  .statChartBlock .mainGrayBox[data-v-106c86ed]{
    /* padding: 26px 21px 17px 11px; */
    border-radius: 12px;
  }
  .statChartBlock .tableNav.chartTabs[data-v-106c86ed]{
    margin: 0px 0 24px;
    /* margin-bottom: 24px; */
  }
  .statChartBlock .tableNav.chartTabs .el-range-editor.el-input__inner{
    border-radius: 21px;
    width: 300px;
  }
  .statChartBlock .tableNav.chartTabs .el-date-editor .el-range-input{
    font-size: 12px;
    font-weight: 400;
    text-align: left;
    width: 50%;
    padding-left: 5px;
  }
  .statChartBlock .tableNav.chartTabs .el-range-editor .el-range-separator{
    font-size: 15px;
    font-weight: 700;
  }
  .statChartBlock .tableNav.chartTabs ul li a{
    font-size: 15px;
    font-weight: 500;
  }
  .statChartBlock .chartBox[data-v-106c86ed]{
    margin-top: 0;
  }
  .statChartBlock .chartMainHeading[data-v-106c86ed]{
    justify-content: right;
  }

  /* StatChartBlock CSS End Here */

  .chartMainHeading select{
    border-radius: 21px;
  }

  .sales-distribution-chart{
    border-radius: 12px;
    overflow: hidden;
  }

  /* newChart2BlockInnerBox CSS Start Here */

  .newChart2BlockInnerBox .chartHandleBox{
    height: 100%;
    overflow-y: hidden;
    overflow-x:scroll;
    border-radius: 12px;
  }
  .newChart2BlockInnerBox .mainGrayBox{
    border-radius: 12px;
  }
  .commantChatBox{
    border-radius: 12px;
    overflow: hidden;
  }
  .newChart2BlockInnerBox .chartBox{
    padding: 0px 91px 0 110px;
  }
  .newChart2BlockInnerBox .mainGrayBox[data-v-106c86ed]{
    padding: 24px 19px 19px 19px;
  }
  .newChart2BlockInnerBox .tableNav.chartTabs[data-v-106c86ed]{
    margin-top: 0;
  }
  .newChart2BlockInnerBox .commantChatBox{
    /* height: 100%; */
    background: #fff;
    height: 100%;
  }
  .newChart2BlockInnerBox .commantChatBoxInnerBox{
    background-color: #fff;
    height:100%;
    /* overflow: auto; */
  }
  .newChart2BlockInnerBox .commantChatBoxInnerBox  table tbody tr{
    background-color: #FCFCFC;
  }

  .newChart2BlockInnerBox .viewOrderBox{
    margin: 0;
  }

  /* Category Component Style Starts Here */
  .categoryTableWrapBox{
    overflow: hidden;
    margin: 0px;
  }
  .categoryTableWrapBox .categoryTableScrollBox{
    /* min-height: 750px; */
    max-height: 750px;
    overflow: auto;
    width: 100%;
    border: 1px solid #CECECE;
    border-radius: 12px 12px 0px 0px;
  }
  .categoryTableWrapBox .categoryTableScrollBox table {
    border-collapse: collapse;
    width: 100%;
    background-color: #FCFCFC;
    /* border-radius: 12px 12px 0px 0px; */
  }

  .categoryTableWrapBox .categoryTableScrollBox table thead, .categoryTableWrapBox .categoryTableScrollBox table thead tr {
    background-color: #fff;
    border-radius: 12px 12px 0px 0px;
    background-color: #FCFCFC;
  }
  .categoryTableWrapBox .categoryTableScrollBox  table thead th {
    padding: 25px 13px 25px 13px;
  }
  .categoryTableWrapBox .categoryTableScrollBox table thead tr th:nth-last-child(1) {
    text-align: center;
  }

  .categoryTableWrapBox .categoryTableScrollBox  table tbody tr {
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  .categoryTableWrapBox .categoryTableScrollBox  table  tbody tr td {
    padding: 6px 13px 6px 13px !important;
    border-top: 0.5px solid #E8E8E8;
  }
  .categoryTableWrapBox .categoryTableScrollBox  table  tbody tr td:nth-last-child(1) .ctmDataTableActionBtn{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .categoryTableWrapBox .categoryTableScrollBox  .editFieldTitle input{
    background-color: #FCFCFC;
  }

  .categoryTableWrapBox .categoryTableScrollBox .ctmDataTableActionBtn button {
    background-color: transparent;
    padding: 0px;
    border: none;
    outline: none !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  /* Category Component Style End Here */

  .redCtmTable {
    padding: 0px;
    margin-bottom: 20px;
    border: 1px solid #cccccc;
    border-radius: 8px!important;
    overflow: hidden;
  }
  .tableNav {
    background: #15223d;
    padding: 10px 20px;
  }
  .tableNav ul {
    list-style: none;
    padding: 0px;
    margin-bottom: 0px;
  }
  .redCtmTable .tableNav ul li {
    display: inline-block;
    margin-right: 55px;
  }
  .redCtmTable .tableNav .el-date-editor{
    background-color: #15223d!important;
  }
  .tableNav ul li a {
    display: inline-block;
    color: #fff;
    font-size: 16px;
    text-transform: capitalize;
    position: relative;
    font-weight: 500;
  }
  .tableNav ul li a::after {
    content: "";
    position: absolute;
    bottom: -19px;
    left: 0;
    right: 0px;
    margin: 0 auto;
    width: 100%;
    height: 3px;
    background: #1ec2c2;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .tableNav ul li a:hover::after,
  .tableNav ul li.active a::after {
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .db_info {
    margin-top: 0;
    padding: 20px;
    background: #fff;
  }
  .db_info ul {
    list-style: none;
    margin: 0px;
    padding: 0px;
  }
  .db_info ul li {
    display: inline-block;
    width: 19.7%;
    border-right: 2px solid #f5f5f5;
    padding-left: 20px;
    text-align: center;
    /* padding-top: 20px !important; */
    /* padding-bottom: 20px !important; */
  }
  /* .el-range-editor.el-input__inner{
    background-color: #15223d!important;
  } */
  .redCtmTable .tableNav .el-date-editor{
    background-color: #15223d;
  }
  .redCtmTable .tableNav .el-date-editor .el-range-input{
    background-color: #15223d;
  }
  .db_info ul li:nth-child(1) {
    padding: 0px;
  }

  .db_info ul li:nth-last-child(1) {
    border: none;
  }
  .db_info ul li p {
    color: #000000;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0;
  }
  .amountList h2 {
    font-size: 30px;
    color: #000;
    margin-bottom: 0px;
    font-weight: 600;
    line-height: 37px;
  }
  .amountList {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .amountList span {
    font-size: 20px;
    color: #000;
    font-weight: 700;
    display: inline-block;
    line-height: 17px;
  }
  .amountList svg {
    margin-right: 5px;
  }
  .tableNav ul li a span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #e6e6e6 !important;
    padding: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-left: 24px;
  }
  .tableNav ul li a span svg {
    width: 20px;
    height: 18px;
    fill: #1ec2c2;
  }
  .el-date-editor .el-range-separator {
    width: 32px !important;
  }
  .chartsBlock {
    margin-top: 50px;
  }
  .mainGrayBox {
    background: #fff;
    padding: 30px 30px;
    box-shadow: 1px 1px 11px #e8e8e8;
    height: 100%;
  }
  .chartMainHeading {
    display: flex;
    justify-content: space-between;
  }
  .chartBox {
    margin-top: 20px;
  }
  .chartMainHeading h2 {
    font-size: 30px;
    color: #14223d;
    margin-bottom: 0px;
  }
  .chartMainHeading select {
    width: 200px;
  }
  .tableNav.chartTabs {
    margin-top: 20px;
    background: transparent;
    padding: 0px;
  }
  .tableNav.chartTabs ul li a {
    color: #14223d;
    font-size: 15px;
  }
  .tableNav.chartTabs ul li a::after {
    background: #14223d;
    bottom: -2px;
  }

  .packageLine .progressBarRow {
    max-width: 100%;
  }
  .progressBarRow {
    max-width: 452px;
  }
  .progressBarTrack {
    background: #f3f3f3;
    width: 100%;
    height: 10px;
    margin-bottom: 7px;
  }
  .progressBar {
    height: 100%;
    width: 40%;
    background: #1ec2c2;
  }
  .progressBarCtmRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .progressBarCtmColumn p {
    font-size: 16px;
    margin-bottom: 0px;
    color: #000;
    font-weight: 400;
  }
  .orderTableComponent {
    background-color: #fff;
    border-radius: 12px;
  }
  .orderTableComponent .tableMainWrapBox,.orderTableComponent .tableScrollWrap{
    border-radius: 12px;
  }
  .orderTableComponent .tableMainWrapBox table tbody{
    background-color: #FCFCFC!important;
  }

</style>
